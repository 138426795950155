import { BigNumberish } from '@ethersproject/bignumber'
import { GameAddresses, getAddressesForGobblerInstance } from 'config'
import { useMemo } from 'react'

const useContractAddresses = (
  chainId: BigNumberish | undefined | null,
): GameAddresses | undefined | null => {
  const addresses = useMemo(() => {
    if (chainId === undefined) {
      return undefined
    }
    if (chainId === null) {
      return null
    }
    return getAddressesForGobblerInstance(chainId)
  }, [chainId])
  return addresses
}

export { useContractAddresses }
